import { types } from "./general_actions";

const initialState = {
  getOrgCharts: [],
  getCboDuty: [],
  getCboEmployees: [],
  getCboProjects: [],
  getCboHandOver: [],
  getCboProjectsW29f2005:[]
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_CBO_ORGCHART_SUCCESS:
      return {
        ...state,
        getOrgCharts: action.data
      };

    case types.GET_CBO_DUTY_SUCCESS:
      return {
        ...state,
        getCboDuty: action.data
      };
    case types.GET_CBO_PROJECT_SUCCESS:
      return {
        ...state,
        getCboProjects: action.data
      };
    case types.GET_CBO_EMPLOYEE_SUCCESS:
      return {
        ...state,
        getCboEmployees: action.data
      };

    default:
      return state;
  }
}

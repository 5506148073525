/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 31/10/2019
 * @Example
 */

import {types} from "./W94F1000_actions";

const initialState = {
    listChart: null,
    listGroupChart: null,
};

export default function(state = initialState,action = {}) {
    if(action.type === types.W94F1000_GET_GROUP_LIST_SUCCESS)
    {
        return {
            ...state,
            listGroupChart: action.data
        }
    }
    else if(action.type === types.W94F1000_GET_LIST)
    {
        return {
            ...state,
            listChart: action.data
        }
    }
    return state
}

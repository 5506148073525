// action types
export const types = {
    GET_AUTH: "GET_AUTH",

    LIST: 'LIST',
    LIST_SUCCESS: 'LIST_SUCCESS',

    LIST_MENU: 'LIST_MENU',
    LIST_MENU_SUCCESS: 'LIST_MENU_SUCCESS',

    LIST_GROUP_MENU: 'LIST_GROUP_MENU',
    LIST_GROUP_MENU_SUCCESS: 'LIST_GROUP_MENU_SUCCESS',

    LIST_FORM_INFO: 'LIST_FORM_INFO',

    LIST_BIRTHDAY: 'LIST_BIRTHDAY',
    LIST_BIRTHDAY_SUCCESS: 'LIST_BIRTHDAY_SUCCESS',
};

export function getAuth(params, cb) {
    return {
        type: types.GET_AUTH,
        params,
        cb
    }
}

export function getList(cb) {
    return {
        type: types.LIST,
        cb
    }
}

export function getMenu(params,cb) {
    return {
        type: types.LIST_MENU,
        params,
        cb
    }
}

export function getGroupMenu(params,cb) {
    return {
        type: types.LIST_GROUP_MENU,
        params,
        cb
    }
}

export function getFormInfo(cb) {
    return {
        type: types.LIST_FORM_INFO,
        cb
    }
}

export function getListBirthday(cb) {
    return {
        type: types.LIST_BIRTHDAY,
        cb
    }
}

import
{
    all
} from 'redux-saga/effects';

import{
    watchLoading,
    watchUploadFile,
    watchLogin,
    watchGetTokenLogin,
    watchChangeThemes,
    watchUpdateFilters,
    watchGetThemes,
} from "../redux/main/main_sagas";

import GeneralSagas from "../redux/general/general_sagas";

import {
    watchGetAuth,
    watchGetMenu,
    watchList,
    watchGetFormInfo,
    watchGetListBirthday,
} from "../redux/home/home_sagas";

import AdminSagas from "../redux/admin/admin_sagas";

import {
    watchGetList,
    watchGetGroupList,
    watchGetDetail,
    watchGetFormMaster,
    watchGetStructReport,
} from "../redux/W9X/W94F1000/W94F1000_sagas";

export default function* rootSaga() {
    yield all([
        watchLoading(),
        watchUploadFile(),
        watchLogin(),
        watchGetTokenLogin(),
        watchChangeThemes(),
        watchUpdateFilters(),
        watchGetThemes(),

        //Admin
        AdminSagas(),

        //Generals
        GeneralSagas(),

        //Home
        watchGetAuth(),
        watchGetMenu(),
        watchList(),
        watchGetFormInfo(),
        watchGetListBirthday(),

        //W94F1000
        watchGetList(),
        watchGetGroupList(),
        watchGetDetail(),
        watchGetFormMaster(),
        watchGetStructReport(),
    ])
}

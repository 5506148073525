/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @file src/routes/routes.js
 */

import React, { Component } from 'react';
import { Router, Route, browserHistory,IndexRoute } from 'react-router'
import App from '../container/app';
import Config from '../config';

import ModuleNotFound from '../components/not-found/not-found';
import ErrorPage from '../components/error-500/error-500';
import NotPermission from '../components/not-permission/not-permission';
import W94F1000 from '../components/W9X/W94/W94F1000/W94F1000';
import W94F1001 from '../components/W9X/W94/W94F1000/W94F1001';
import W94F1002 from '../components/W9X/W94/W94F1000/W94F1002';

import Dashboard from '../components/W9X/W94/W94F1000/W94F1000';
import AuthPage from '../components/login/auth';

let oldRouter = '';
class Routes extends Component {

    onRouteUpdate = (e) => {
        window.scrollTo(0, 0);
        // if (Config.filters) {
        //     Config.filters.setFilter(null); //Clear general filters on header
        // }
        // ===== DEBUGGER =====
        if (oldRouter !== browserHistory.getCurrentLocation()?.pathname && Config?.debugger) {
            let dataDebuggerError = Config.debugger.dataDebuggerError;
            Config.debugger.setData(dataDebuggerError);
        }
        oldRouter = browserHistory.getCurrentLocation()?.pathname;
        // ===== DEBUGGER =====
    };

    render(){
        return (
            <Router onUpdate={this.onRouteUpdate} history={browserHistory}>
                <Route path={Config.getRootPath()} component={App}>
                    <IndexRoute component={Dashboard}/>

                    <Route path="admin"
                           component={()=><section>Redirecting...</section>}/>

                    <Route path="dashboard" component={Dashboard} />
                    <Route path="auth" component={AuthPage} />

                    <Route path="error-page" component={ErrorPage}/>

                    <Route path="not-permission" component={NotPermission}/>

                    <Route path="W94F1000" component={W94F1000}/>
                    <Route path="W94F1001" component={W94F1001}/>
                    <Route path="W94F1002" component={W94F1002}/>

                    <Route path='*' exact={true} component={ModuleNotFound} />
                </Route>
            </Router>
        );
    };
}

export default Routes;

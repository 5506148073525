export const localize = {
    "vi": {
        "BI_403_loi": "Bạn không có quyền truy cập để xem trang này",
        "BI_404_loi": "Trang bạn đang tìm kiếm có thể đã bị xóa, có tên thay đổi hoặc tạm thời không có.",
        "BI_AC_vui_long_chon_y_kien_cua_minh": "Anh/Chị vui lòng chọn ý kiến của mình tương ứng với mức độ đồng ý cho những dịch vụ cung cấp dưới đây",
        "BI_Ban_co_chac_muon_xoa?": "Bạn có chắc muốn xoá?",
        "BI_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "Bạn đã đăng nhập sai quá số lần cho phép, vui lòng chờ %p để đăng nhập lại",
        "BI_Ban_phai_chon_doanh_nghiep": "Bạn phải chọn doanh nghiệp",
        "BI_Ban_phai_nhap_cac_thong_tin": "Bạn phải nhập các thông tin",
        "BI_Ban_phai_nhap_thong_tin": "Bạn phải nhập thông tin",
        "BI_Bao_cao": "Báo cáo",
        "BI_Ca_nhan": "Cá nhân",
        "BI_Cac_gop_y_khac": "Các góp ý khác",
        "BI_Cac_tham_so_la_bat_buoc": "Các tham số là bắt buộc",
        "BI_Cac_thong_so_luong": "Các thông số lương",
        "BI_Cac_tieu_chi_la_bat_buoc_nhap": "Các chỉ tiêu bắt buộc nhập",
        "BI_Cai_dat": "Cài đặt",
        "BI_Cap_co_cau": "Cấp cơ cấu",
        "BI_Chi_tiet": "Chi tiết",
        "BI_Chon": "Chọn",
        "BI_Chu_de": "Chủ đề",
        "BI_Chua_co_lien_ket_nao": "Chưa có liên kết nào",
        "BI_Chua_dap_ung": "Chưa đáp ứng",
        "BI_Chuc_nang_chua_phat_trien": "Chức năng chưa phát triển",
        "BI_Chuc_vu_bat_buoc_nhap": "Chức vụ bắt buộc nhập",
        "BI_Co1": "Có",
        "BI_Co_cau_cha": "Cơ cấu cha",
        "BI_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "Có lỗi xảy ra trong quá trình xử lý.",
        "BI_Cong_trinh/Cong_ty": "Công trình/Công ty",
        "BI_Dang_nhap": "Đăng nhập",
        "BI_Dang_nhap_lai2": "Bạn đã đăng nhập tài khoản trên một thiết bị khác. Vui lòng đăng nhập lại.",
        "BI_Dang_xuat": "Dạng xuất",
        "BI_Dang_xuat1": "Đăng xuất",
        "BI_Danh_sach_bao_cao": "Danh sách báo cáo",
        "BI_Dap_ung": "Đáp ứng",
        "BI_Dinh_dang_file_khong_hop_le": "Định dạng file không hợp lệ",
        "BI_Doi_tuong_ap_dung": "Đối tượng áp dụng",
        "BI_Dong1": "Đóng",
        "BI_Dong_y": "Đồng ý",
        "BI_Dung_luong_File_khong_duoc_lon_hon": "Dung lượng File không được lớn hơn",
        "BI_Duyet": "Duyệt",
        "BI_Ghi_chu": "Ghi chú",
        "BI_Gia_tri_bat_buoc_nhap": "Giá trị bắt buộc nhập!",
        "BI_Giay": "Giây",
        "BI_Gui": "Gửi",
        "BI_Hai_long_can_cai_tien_them": "Hài lòng cần cải tiến thêm",
        "BI_Hanh_dong": "Hành động",
        "BI_He_thong_dang_trong_qua_trinh_nang_cap": "Hệ thống đang trong quá trình nâng cấp.",
        "BI_Ho_so_nhan_vien": "Hồ sơ nhân viên",
        "BI_Hoan_toan_hai_long": "Hoàn toàn hài lòng",
        "BI_Khac": "Khác",
        "BI_Khao_sat_y_kien_khach_hang": "Khảo sát ý kiến khách hàng",
        "BI_Khoi_la_bat_buoc_nhap": "Khối bắt buộc nhập",
        "BI_Khong": "Không",
        "BI_Khong_co_dong_du_lieu_duoc_cap_nhat": "Không có dòng dữ liệu được cập nhật",
        "BI_Khong_co_thong_bao": "Không có thông báo",
        "BI_Khong_du_quyen": "Bạn không đủ quyền, Vui lòng đăng nhập lại",
        "BI_Khong_tim_thay_trang": "KHÔNG TÌM THẤY TRANG",
        "BI_Kinh_doanh": "Kinh doanh",
        "BI_Lich_su_tac_dong": "Lịch sử tác động",
        "BI_Loai_doi_tuong_ap_dung": "Loại đối tượng áp dụng",
        "BI_Loai_quy_trinh": "Loại quy trình",
        "BI_Loc_chon_hanh_dong": "Lọc chọn hành động",
        "BI_Loi_chua_xac_dinh": "Lỗi chưa xác định",
        "BI_Loi_khong_lay_duoc_token_CDN": "Lỗi không lấy được CDN token",
        "BI_Luu": "Lưu",
        "BI_Luu_thanh_cong": "Lưu thành công",
        "BI_Luu_thong_tin_chung_tu_khong_thanh_cong": "Lưu thông tin chứng từ không thành công",
        "BI_Ma_co_cau": "Mã cơ cấu",
        "BI_Mat_khau": "Mật khẩu",
        "BI_Mau_giao_dien": "Màu giao diện",
        "BI_Mo_ta": "Mô tả",
        "BI_Mua_hang": "Mua hàng",
        "BI_Muc_do_dong_y": "Mức độ đồng ý",
        "BI_Ngay": "Ngày",
        "BI_Ngay_danh_gia_bat_buoc_nhap": "Ngày đánh giá bắt buộc nhập",
        "BI_Ngay_tac_dong": "Ngày tác động",
        "BI_Nghiep_vu": "Nghiệp vụ",
        "BI_Nguoi_danh_gia": "Người đánh giá",
        "BI_Nguoi_danh_gia_bat_buoc_nhap": "Người đánh giá bắt buộc nhập",
        "BI_Nguoi_dung_khong_su_dung": "Người dùng không sử dụng",
        "BI_Nguoi_uy_quyen": "Người ủy quyền",
        "BI_Nhan_vien": "Nhân viên",
        "BI_Nhap_mat_khau": "Nhập mật khẩu",
        "BI_Nhap_tu_tim_kiem": "Nhập từ tìm kiếm",
        "BI_Nho_mat_khau": "Nhớ mật khẩu",
        "BI_Nho_mat_khau1": "Nhớ mật khẩu",
        "BI_Nhom_bao_cao": "Nhóm báo cáo",
        "BI_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "Những ý kiến này sẽ được Công ty chúng tôi ghi nhận và làm cơ sở để nâng cao chất lượng dịch vụ nhằm đáp ứng nhu cầu của khách hàng ngày càng tốt hơn",
        "BI_No_data": "Không có dữ liệu",
        "BI_No_link": "Không tìm thấy đường dẫn",
        "BI_Noi_dung_can_tim_kiem": "Nội dung cần tìm kiếm",
        "BI_Phien_ban": "Phiên bản",
        "BI_Quay_lai": "Quay lại",
        "BI_Quy_uoc_nhu_sau": "Quy ước như sau",
        "BI_So_dong": "Số dòng",
        "BI_So_thu_tu": "Số thứ tự",
        "BI_Sua": "Sửa",
        "BI_Tai_khoan_bi_khoa_10_phut": "Tài khoản bị khoá, hãy thử lại sau 10 phút",
        "BI_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Tài khoản đăng nhập hoặc mật khẩu không đúng.",
        "BI_Tai_khoan_khong_co_quyen": "Bạn không có quyền truy cập vào hệ thống.",
        "BI_Tam_dap_ung_Can_cai_tien_them": "Tạm đáp ứng, cần phải cải tiến thêm",
        "BI_Tang_luong_truoc_thoi_han": "Tăng lương trước thời hạn",
        "BI_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Tập tin đính kèm đang được xử lý",
        "BI_Tat_ca": "Tất cả",
        "BI_Ten_Dang_Nhap": "Tên đăng nhập",
        "BI_Tham_so_truyen_vao_khong_hop_le": "Tham số truyền vào không hợp lệ",
        "BI_Them": "Thêm",
        "BI_Them_moi1": "Thêm mới",
        "BI_Thoi_gian": "Thời gian",
        "BI_Thong_bao": "Thông báo",
        "BI_Thong_bao1": "Thông Báo",
        "BI_Thong_baoU": "Thông báo",
        "BI_Thong_tin_chinh": "Thông tin chính",
        "BI_Thong_tin_cung_cap_khong_hop_le": "Thông tin cung cấp không hợp lệ!",
        "BI_Tim_kiem": "Tìm kiếm",
        "BI_Tong": "Tổng",
        "BI_Tong_diem": "Tổng điểm",
        "BI_Trang": "Trang",
        "BI_Trang_Chu": "Trang chủ",
        "BI_Trang_thai": "Trạng thái",
        "BI_Truy_cap_khong_hop_le": "Truy cập không hợp lệ",
        "BI_Tu_choi": "Từ chối",
        "BI_VoucherID_khong_duoc_de_trong": "VoucherID không được để trống",
        "BI_Vui_long_truy_cap_sau": "Vui lòng truy cập sau!",
        "BI_Xem": "Xem",
        "BI_Xem_tat_ca": "Xem tất cả",
        "BI_Xem_them": "Xem thêm",
        "BI_Xem_truoc": "Xem trước",
        "BI_Xin_cam_on": "Xin cảm ơn",
        "BI_Xoa": "Xóa",
        "BI_Xuat_bao_cao": "Xuất báo cáo",
        "BI_Xuat_du_lieu": "Xuất dữ liệu",
        "BI_Xuat_du_lieu_thanh_cong": "Xuất dữ liệu thành công.",
        "BI_Y_kien_dong_gop": "Ý kiến đóng góp",
        "BI_dang_nhap_lai": "đăng nhập lại",
        "BI_khong_co_du_lieu": "Không có dữ liệu",
        "BI_ko_du_quyen": "Bạn không đủ quyền, Vui lòng đăng nhập lại",
        "BI_phut": "phút",
        "BI_trong": "trống"
    },
    "en": {
        "BI_403_loi": "You do not have permission to view this page",
        "BI_404_loi": "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
        "BI_AC_vui_long_chon_y_kien_cua_minh": "Please let us know your evaluation on service level",
        "BI_Ban_co_chac_muon_xoa?": "Are you sure to delete?",
        "BI_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai",
        "BI_Ban_phai_chon_doanh_nghiep": "Ban_phai_chon_doanh_nghiep",
        "BI_Ban_phai_nhap_cac_thong_tin": "You have to enter the information.",
        "BI_Ban_phai_nhap_thong_tin": "You have to enter the information.",
        "BI_Bao_cao": "Report",
        "BI_Ca_nhan": "Personal",
        "BI_Cac_gop_y_khac": "Other feedback",
        "BI_Cac_tham_so_la_bat_buoc": "The parameters are compulsory",
        "BI_Cac_thong_so_luong": "Salary Parameters",
        "BI_Cac_tieu_chi_la_bat_buoc_nhap": "Compulsory criteria",
        "BI_Cai_dat": "Settings",
        "BI_Cap_co_cau": "Cap_co_cau",
        "BI_Chi_tiet": "Detail",
        "BI_Chon": "Select",
        "BI_Chu_de": "Themes",
        "BI_Chua_co_lien_ket_nao": "There is no link",
        "BI_Chua_dap_ung": "Not qualified",
        "BI_Chuc_nang_chua_phat_trien": "Pending features",
        "BI_Chuc_vu_bat_buoc_nhap": "Position is required",
        "BI_Co1": "Yes",
        "BI_Co_cau_cha": "Co_cau_cha",
        "BI_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "An error occurred during processing.",
        "BI_Cong_trinh/Cong_ty": "Project/Company",
        "BI_Dang_nhap": "Log in",
        "BI_Dang_nhap_lai2": "You are already logged in on a different device. Please login again.",
        "BI_Dang_xuat": "Export Type",
        "BI_Dang_xuat1": "Logout",
        "BI_Danh_sach_bao_cao": "List reports",
        "BI_Dap_ung": "Qualified",
        "BI_Dinh_dang_file_khong_hop_le": "Invalid file format",
        "BI_Doi_tuong_ap_dung": "Apply for",
        "BI_Dong1": "Close",
        "BI_Dong_y": "OK",
        "BI_Dung_luong_File_khong_duoc_lon_hon": "File size must not be larger than",
        "BI_Duyet": "Approve",
        "BI_Ghi_chu": "Notes",
        "BI_Gia_tri_bat_buoc_nhap": "Value Obligatory",
        "BI_Giay": "Second",
        "BI_Gui": "Send",
        "BI_Hai_long_can_cai_tien_them": "Satifactory and needs improvement",
        "BI_Hanh_dong": "Action",
        "BI_He_thong_dang_trong_qua_trinh_nang_cap": "The system is currently undergoing maintenance.",
        "BI_Ho_so_nhan_vien": "Employee profile",
        "BI_Hoan_toan_hai_long": "Fully satisfied",
        "BI_Khac": "Other",
        "BI_Khao_sat_y_kien_khach_hang": "Customer survey",
        "BI_Khoi_la_bat_buoc_nhap": "Division is required",
        "BI_Khong": "No",
        "BI_Khong_co_dong_du_lieu_duoc_cap_nhat": "No data row is updated",
        "BI_Khong_co_thong_bao": "No notifications",
        "BI_Khong_du_quyen": "Insufficient permissions",
        "BI_Khong_tim_thay_trang": "PAGE NOT FOUND",
        "BI_Kinh_doanh": "Sales ",
        "BI_Lich_su_tac_dong": "Transaction History",
        "BI_Loai_doi_tuong_ap_dung": "Applied Object Types",
        "BI_Loai_quy_trinh": "Workflow type",
        "BI_Loc_chon_hanh_dong": "Select Action",
        "BI_Loi_chua_xac_dinh": "Error is not determined",
        "BI_Loi_khong_lay_duoc_token_CDN": "Error while getting CDN token ",
        "BI_Luu": "Save",
        "BI_Luu_thanh_cong": "Saved successfully",
        "BI_Luu_thong_tin_chung_tu_khong_thanh_cong": "Document data cannot be saved",
        "BI_Ma_co_cau": "Organization Code",
        "BI_Mat_khau": "Password",
        "BI_Mau_giao_dien": "Theme color",
        "BI_Mo_ta": "Description",
        "BI_Mua_hang": "Purchase",
        "BI_Muc_do_dong_y": "Agreement level",
        "BI_Ngay": "Date",
        "BI_Ngay_danh_gia_bat_buoc_nhap": "Evaluation date required",
        "BI_Ngay_tac_dong": "Trans Date",
        "BI_Nghiep_vu": "Transaction",
        "BI_Nguoi_danh_gia": "Evaluation Person",
        "BI_Nguoi_danh_gia_bat_buoc_nhap": "Evaluation person is required",
        "BI_Nguoi_dung_khong_su_dung": "User is disabled",
        "BI_Nguoi_uy_quyen": "Authorizing person",
        "BI_Nhan_vien": "Employee",
        "BI_Nhap_mat_khau": "Enter password",
        "BI_Nhap_tu_tim_kiem": "Enter search key words",
        "BI_Nho_mat_khau": "Save login and password",
        "BI_Nho_mat_khau1": "Remember me",
        "BI_Nhom_bao_cao": "Group reports",
        "BI_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "This feedback will be taken by our company in oder to improve our service quality level and provide better service to our customers.",
        "BI_No_data": "No Data",
        "BI_No_link": "The link was not found  ",
        "BI_Noi_dung_can_tim_kiem": "Content to search",
        "BI_Phien_ban": "Version",
        "BI_Quay_lai": "Back",
        "BI_Quy_uoc_nhu_sau": "Agreed as follows",
        "BI_So_dong": "Number of rows",
        "BI_So_thu_tu": "Order No",
        "BI_Sua": "Edit",
        "BI_Tai_khoan_bi_khoa_10_phut": "Your account is locked, please try again in 10 minutes",
        "BI_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Incorrect login or password",
        "BI_Tai_khoan_khong_co_quyen": "You doo not have the permissioin to access the system.",
        "BI_Tam_dap_ung_Can_cai_tien_them": "Temporarily satisfied, needs improvement",
        "BI_Tang_luong_truoc_thoi_han": "Increasing salary ahead of schedule",
        "BI_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Attachments in processing",
        "BI_Tat_ca": "All",
        "BI_Ten_Dang_Nhap": "Username",
        "BI_Tham_so_truyen_vao_khong_hop_le": "Input parameters invalid",
        "BI_Them": "Add",
        "BI_Them_moi1": "Add new",
        "BI_Thoi_gian": "Time",
        "BI_Thong_bao": "Announcement",
        "BI_Thong_bao1": "Alert",
        "BI_Thong_baoU": "Notifications",
        "BI_Thong_tin_chinh": "Main",
        "BI_Thong_tin_cung_cap_khong_hop_le": "Provided data invalid",
        "BI_Tim_kiem": "Finding Information",
        "BI_Tong": "Total",
        "BI_Tong_diem": "Total points",
        "BI_Trang": "Page",
        "BI_Trang_Chu": "Home Page",
        "BI_Trang_thai": "Status",
        "BI_Truy_cap_khong_hop_le": "Invalid access",
        "BI_Tu_choi": "Reject",
        "BI_VoucherID_khong_duoc_de_trong": "VoucherID cannot be blank",
        "BI_Vui_long_truy_cap_sau": "Vui_long_truy_cap_sau",
        "BI_Xem": "View",
        "BI_Xem_tat_ca": "Search all",
        "BI_Xem_them": "See more",
        "BI_Xem_truoc": "Preview",
        "BI_Xin_cam_on": "Xin_cam_on",
        "BI_Xoa": "Delete",
        "BI_Xuat_bao_cao": "Export report",
        "BI_Xuat_du_lieu": "Export Data",
        "BI_Xuat_du_lieu_thanh_cong": "Export data is success",
        "BI_Y_kien_dong_gop": "Feedback",
        "BI_dang_nhap_lai": "Login again",
        "BI_khong_co_du_lieu": "No data",
        "BI_ko_du_quyen": "Insufficient permissions. Please log in again.",
        "BI_phut": "minute(s)",
        "BI_trong": "empty"
    },
    "ja": {
        "BI_403_loi": "",
        "BI_404_loi": "",
        "BI_AC_vui_long_chon_y_kien_cua_minh": "",
        "BI_Ban_co_chac_muon_xoa?": "",
        "BI_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "",
        "BI_Ban_phai_chon_doanh_nghiep": "",
        "BI_Ban_phai_nhap_cac_thong_tin": "",
        "BI_Ban_phai_nhap_thong_tin": "",
        "BI_Bao_cao": "",
        "BI_Ca_nhan": "Personal",
        "BI_Cac_gop_y_khac": "",
        "BI_Cac_tham_so_la_bat_buoc": "",
        "BI_Cac_thong_so_luong": "",
        "BI_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "BI_Cai_dat": "",
        "BI_Cap_co_cau": "",
        "BI_Chi_tiet": "詳細",
        "BI_Chon": "Select",
        "BI_Chu_de": "",
        "BI_Chua_co_lien_ket_nao": "",
        "BI_Chua_dap_ung": "",
        "BI_Chuc_nang_chua_phat_trien": "",
        "BI_Chuc_vu_bat_buoc_nhap": "",
        "BI_Co1": "",
        "BI_Co_cau_cha": "",
        "BI_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "BI_Cong_trinh/Cong_ty": "",
        "BI_Dang_nhap": "ログイン",
        "BI_Dang_nhap_lai2": "",
        "BI_Dang_xuat": "",
        "BI_Dang_xuat1": "",
        "BI_Danh_sach_bao_cao": "",
        "BI_Dap_ung": "",
        "BI_Dinh_dang_file_khong_hop_le": "",
        "BI_Doi_tuong_ap_dung": "",
        "BI_Dong1": "",
        "BI_Dong_y": "",
        "BI_Dung_luong_File_khong_duoc_lon_hon": "",
        "BI_Duyet": "承認",
        "BI_Ghi_chu": " 言い草",
        "BI_Gia_tri_bat_buoc_nhap": "",
        "BI_Giay": "",
        "BI_Gui": "Send",
        "BI_Hai_long_can_cai_tien_them": "",
        "BI_Hanh_dong": "",
        "BI_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "BI_Ho_so_nhan_vien": "従業員ファイル",
        "BI_Hoan_toan_hai_long": "",
        "BI_Khac": "その他",
        "BI_Khao_sat_y_kien_khach_hang": "",
        "BI_Khoi_la_bat_buoc_nhap": "",
        "BI_Khong": "いいえ",
        "BI_Khong_co_dong_du_lieu_duoc_cap_nhat": "",
        "BI_Khong_co_thong_bao": "",
        "BI_Khong_du_quyen": "",
        "BI_Khong_tim_thay_trang": "",
        "BI_Kinh_doanh": "Sales ",
        "BI_Lich_su_tac_dong": "",
        "BI_Loai_doi_tuong_ap_dung": "",
        "BI_Loai_quy_trinh": "",
        "BI_Loc_chon_hanh_dong": "",
        "BI_Loi_chua_xac_dinh": "",
        "BI_Loi_khong_lay_duoc_token_CDN": "",
        "BI_Luu": "保存",
        "BI_Luu_thanh_cong": "",
        "BI_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "BI_Ma_co_cau": "",
        "BI_Mat_khau": "パスワード",
        "BI_Mau_giao_dien": "",
        "BI_Mo_ta": "",
        "BI_Mua_hang": "購入管理",
        "BI_Muc_do_dong_y": "",
        "BI_Ngay": "年月日 \t",
        "BI_Ngay_danh_gia_bat_buoc_nhap": "",
        "BI_Ngay_tac_dong": "",
        "BI_Nghiep_vu": "",
        "BI_Nguoi_danh_gia": "",
        "BI_Nguoi_danh_gia_bat_buoc_nhap": "",
        "BI_Nguoi_dung_khong_su_dung": "",
        "BI_Nguoi_uy_quyen": "",
        "BI_Nhan_vien": "",
        "BI_Nhap_mat_khau": "パスワードを入力",
        "BI_Nhap_tu_tim_kiem": "",
        "BI_Nho_mat_khau": "",
        "BI_Nho_mat_khau1": "",
        "BI_Nhom_bao_cao": "",
        "BI_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "BI_No_data": "",
        "BI_No_link": "",
        "BI_Noi_dung_can_tim_kiem": "",
        "BI_Phien_ban": "",
        "BI_Quay_lai": "",
        "BI_Quy_uoc_nhu_sau": "",
        "BI_So_dong": "",
        "BI_So_thu_tu": "",
        "BI_Sua": "Edit",
        "BI_Tai_khoan_bi_khoa_10_phut": "",
        "BI_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "BI_Tai_khoan_khong_co_quyen": "",
        "BI_Tam_dap_ung_Can_cai_tien_them": "",
        "BI_Tang_luong_truoc_thoi_han": "",
        "BI_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "BI_Tat_ca": "",
        "BI_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "BI_Tham_so_truyen_vao_khong_hop_le": "",
        "BI_Them": "Add",
        "BI_Them_moi1": "新しく作る",
        "BI_Thoi_gian": "時間",
        "BI_Thong_bao": "Announcement",
        "BI_Thong_bao1": "",
        "BI_Thong_baoU": "",
        "BI_Thong_tin_chinh": "",
        "BI_Thong_tin_cung_cap_khong_hop_le": "",
        "BI_Tim_kiem": "",
        "BI_Tong": "",
        "BI_Tong_diem": "",
        "BI_Trang": "",
        "BI_Trang_Chu": "ホームページ",
        "BI_Trang_thai": "ステータス",
        "BI_Truy_cap_khong_hop_le": "",
        "BI_Tu_choi": "拒絶",
        "BI_VoucherID_khong_duoc_de_trong": "",
        "BI_Vui_long_truy_cap_sau": "",
        "BI_Xem": "表示",
        "BI_Xem_tat_ca": "",
        "BI_Xem_them": "",
        "BI_Xem_truoc": "",
        "BI_Xin_cam_on": "",
        "BI_Xoa": "Delete",
        "BI_Xuat_bao_cao": "レポートのエクスポート",
        "BI_Xuat_du_lieu": "",
        "BI_Xuat_du_lieu_thanh_cong": "",
        "BI_Y_kien_dong_gop": "",
        "BI_dang_nhap_lai": "再度ログオンを",
        "BI_khong_co_du_lieu": "",
        "BI_ko_du_quyen": "",
        "BI_phut": "",
        "BI_trong": ""
    },
    "zh": {
        "BI_403_loi": "",
        "BI_404_loi": "",
        "BI_AC_vui_long_chon_y_kien_cua_minh": "",
        "BI_Ban_co_chac_muon_xoa?": "",
        "BI_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "",
        "BI_Ban_phai_chon_doanh_nghiep": "",
        "BI_Ban_phai_nhap_cac_thong_tin": "",
        "BI_Ban_phai_nhap_thong_tin": "",
        "BI_Bao_cao": "",
        "BI_Ca_nhan": "Personal",
        "BI_Cac_gop_y_khac": "",
        "BI_Cac_tham_so_la_bat_buoc": "",
        "BI_Cac_thong_so_luong": "",
        "BI_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "BI_Cai_dat": "",
        "BI_Cap_co_cau": "",
        "BI_Chi_tiet": "细节",
        "BI_Chon": "Select",
        "BI_Chu_de": "",
        "BI_Chua_co_lien_ket_nao": "",
        "BI_Chua_dap_ung": "",
        "BI_Chuc_nang_chua_phat_trien": "",
        "BI_Chuc_vu_bat_buoc_nhap": "",
        "BI_Co1": "",
        "BI_Co_cau_cha": "",
        "BI_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "BI_Cong_trinh/Cong_ty": "",
        "BI_Dang_nhap": "登录",
        "BI_Dang_nhap_lai2": "",
        "BI_Dang_xuat": "",
        "BI_Dang_xuat1": "",
        "BI_Danh_sach_bao_cao": "",
        "BI_Dap_ung": "",
        "BI_Dinh_dang_file_khong_hop_le": "",
        "BI_Doi_tuong_ap_dung": "",
        "BI_Dong1": "",
        "BI_Dong_y": "",
        "BI_Dung_luong_File_khong_duoc_lon_hon": "",
        "BI_Duyet": "批准",
        "BI_Ghi_chu": "注解 ",
        "BI_Gia_tri_bat_buoc_nhap": "",
        "BI_Giay": "",
        "BI_Gui": "Send",
        "BI_Hai_long_can_cai_tien_them": "",
        "BI_Hanh_dong": "",
        "BI_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "BI_Ho_so_nhan_vien": "员工文件",
        "BI_Hoan_toan_hai_long": "",
        "BI_Khac": "其他",
        "BI_Khao_sat_y_kien_khach_hang": "",
        "BI_Khoi_la_bat_buoc_nhap": "",
        "BI_Khong": " 不是",
        "BI_Khong_co_dong_du_lieu_duoc_cap_nhat": "",
        "BI_Khong_co_thong_bao": "",
        "BI_Khong_du_quyen": "",
        "BI_Khong_tim_thay_trang": "",
        "BI_Kinh_doanh": "Sales ",
        "BI_Lich_su_tac_dong": "",
        "BI_Loai_doi_tuong_ap_dung": "",
        "BI_Loai_quy_trinh": "",
        "BI_Loc_chon_hanh_dong": "",
        "BI_Loi_chua_xac_dinh": "",
        "BI_Loi_khong_lay_duoc_token_CDN": "",
        "BI_Luu": "保存",
        "BI_Luu_thanh_cong": "",
        "BI_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "BI_Ma_co_cau": "",
        "BI_Mat_khau": "密码",
        "BI_Mau_giao_dien": "",
        "BI_Mo_ta": "",
        "BI_Mua_hang": "采购管理",
        "BI_Muc_do_dong_y": "",
        "BI_Ngay": "日期",
        "BI_Ngay_danh_gia_bat_buoc_nhap": "",
        "BI_Ngay_tac_dong": "",
        "BI_Nghiep_vu": "",
        "BI_Nguoi_danh_gia": "",
        "BI_Nguoi_danh_gia_bat_buoc_nhap": "",
        "BI_Nguoi_dung_khong_su_dung": "",
        "BI_Nguoi_uy_quyen": "",
        "BI_Nhan_vien": "",
        "BI_Nhap_mat_khau": "输入密码",
        "BI_Nhap_tu_tim_kiem": "",
        "BI_Nho_mat_khau": "",
        "BI_Nho_mat_khau1": "",
        "BI_Nhom_bao_cao": "",
        "BI_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "BI_No_data": "",
        "BI_No_link": "",
        "BI_Noi_dung_can_tim_kiem": "",
        "BI_Phien_ban": "",
        "BI_Quay_lai": "",
        "BI_Quy_uoc_nhu_sau": "",
        "BI_So_dong": "",
        "BI_So_thu_tu": "",
        "BI_Sua": "Edit",
        "BI_Tai_khoan_bi_khoa_10_phut": "",
        "BI_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "BI_Tai_khoan_khong_co_quyen": "",
        "BI_Tam_dap_ung_Can_cai_tien_them": "",
        "BI_Tang_luong_truoc_thoi_han": "",
        "BI_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "BI_Tat_ca": "",
        "BI_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "BI_Tham_so_truyen_vao_khong_hop_le": "",
        "BI_Them": "Add",
        "BI_Them_moi1": "添新",
        "BI_Thoi_gian": "时间",
        "BI_Thong_bao": "Announcement",
        "BI_Thong_bao1": "",
        "BI_Thong_baoU": "",
        "BI_Thong_tin_chinh": "",
        "BI_Thong_tin_cung_cap_khong_hop_le": "",
        "BI_Tim_kiem": "",
        "BI_Tong": "",
        "BI_Tong_diem": "",
        "BI_Trang": "",
        "BI_Trang_Chu": "首页",
        "BI_Trang_thai": "状态",
        "BI_Truy_cap_khong_hop_le": "",
        "BI_Tu_choi": "拒绝",
        "BI_VoucherID_khong_duoc_de_trong": "",
        "BI_Vui_long_truy_cap_sau": "",
        "BI_Xem": "看",
        "BI_Xem_tat_ca": "",
        "BI_Xem_them": "",
        "BI_Xem_truoc": "",
        "BI_Xin_cam_on": "",
        "BI_Xoa": "Delete",
        "BI_Xuat_bao_cao": "出口報告",
        "BI_Xuat_du_lieu": "",
        "BI_Xuat_du_lieu_thanh_cong": "",
        "BI_Y_kien_dong_gop": "",
        "BI_dang_nhap_lai": "以再次登陆",
        "BI_khong_co_du_lieu": "",
        "BI_ko_du_quyen": "",
        "BI_phut": "",
        "BI_trong": ""
    },
    "timestamps": 1653296763403
};
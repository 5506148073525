/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/31/2019
 * @Example 
 */

import Api from '../../services/api';
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "../../redux/general/general_actions";
import {delay} from "redux-saga";
import Config from "../../config";

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return filename = decodeURIComponent(reFileName);
    }
    return filename; // else return original data
}

export default function GeneralSagas() {
    return [
        watchGetOrgCharts(),
        watchGetCboProjects(),
        watchGetCboDuty(),
        watchGetCboEmployees(),
        watchExportExcelTemplate(),
        watchPassParametersExportExcel(),
    ];
}

//get organiaztional..
export function* getOrgCharts(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w00f0010/get-structure', data.params);
        if (response && response.data) {
            yield put({type: types.GET_CBO_ORGCHART_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get organizational error');
    }
}

export function* watchGetOrgCharts() {
    while (true){
        const watcher = yield takeLatest(types.GET_CBO_ORGCHART, getOrgCharts);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get duty..
export function* getCboDuty(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2161/load-drd-produty', data.params);
        if (response && response.data) {
            yield put({type: types.GET_CBO_DUTY_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo duty error');
    }
}

export function* watchGetCboDuty() {
    while (true){
        const watcher = yield takeLatest(types.GET_CBO_DUTY, getCboDuty);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo employee..
export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2005/load-cbo-employee', data.params);
        if (response && response.data) {
            yield put({type: types.GET_CBO_EMPLOYEE_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo employees error');
    }
}

export function* watchGetCboEmployees() {
    while (true){
        const watcher = yield takeLatest(types.GET_CBO_EMPLOYEE, getCboEmployees);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

//get cbo project..
export function* getCboProjects(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2161/load-cbo-project', data.params);
        if (response && response.data) {
            yield put({type: types.GET_CBO_PROJECT_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo projects error');
    }
}

export function* watchGetCboProjects() {
    while (true){
        const watcher = yield takeLatest(types.GET_CBO_PROJECT, getCboProjects);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}


export function* exportExcelTemplate(data) {
    try {
        yield delay(300);
        let response = yield Api.putExport('/export/excel-report-general', data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-bi-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('export excel template error');
    }
}

export function* watchExportExcelTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_EXPORT_EXCEL_TEMPLATE, exportExcelTemplate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* passParametersExportExcel(data) {
    try {
        yield delay(300);
        let response = yield Api.put('/export/passing-parameter', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('pass params report excel error');
    }
}

export function* watchPassParametersExportExcel() {
    while (true) {
        const watcher = yield takeLatest(types.GENERAL_PASS_PARAM_EXPORT_EXCEL, passParametersExportExcel);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}





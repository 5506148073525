import {types} from "./home_actions";

const initialState = {
    login: null,
    lisBirthday: null,
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    switch (action.type){
        case types.LOGIN_SUCCESS:
            return{
                ...state,
                login: action.payload
            };
        case types.LIST_BIRTHDAY_SUCCESS:
            return{
                ...state,
                lisBirthday: action.payload
            };
        default:
            return state;
    }
}

/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 31/10/2019
 * @Example
 */

import Api from '../../../services/api';
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "./W94F1000_actions";
import {delay} from "redux-saga";

export function* getGroupList(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w94f1000/get-report-group', data.params);
        if (response && response.data) {
            yield put({type: types.W94F1000_GET_GROUP_LIST_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get-report-group error');
    }
}

export function* watchGetGroupList() {
    while (true){
        const watcher = yield takeLatest(types.W94F1000_GET_GROUP_LIST, getGroupList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getList(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w94f1000/get-report-detail');
        if (response && response.data) {
            yield put({type: types.W94F1000_GET_LIST_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get-report-detail error');
    }
}

export function* watchGetList() {
    while (true){
        const watcher = yield takeLatest(types.W94F1000_GET_LIST, getList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getDetail(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w94f1000/get-report-detail', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get-report-detail error');
    }
}

export function* watchGetDetail() {
    while (true){
        const watcher = yield takeLatest(types.W94F1000_GET_DETAIL, getDetail);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getFormMaster(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w94f1000/get-form-master', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get-report-detail error');
    }
}

export function* watchGetFormMaster() {
    while (true){
        const watcher = yield takeLatest(types.W94F1002_GET_FORM_MASTER, getFormMaster);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}



//get struct report..
export function* getStructReport(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w94f1000/get-structure-report', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo projects error');
    }
}

export function* watchGetStructReport() {
    while (true){
        const watcher = yield takeLatest(types.GET_STRUCT_REPORT, getStructReport);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

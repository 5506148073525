/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/23 21:26
 * @update 2019/08/23 21:26
 */

import Api from '../../services/api';
import Config from '../../config';
import {delay} from "redux-saga";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types} from "../../redux/main/main_actions";

export function* loading(cb) {
    try {
        yield delay(300);
        const time = new Date().getTime();
        let tokenStore = Config.getLocalStorage('TOKENBI', false, true);
        let settingStore = Config.getLocalStorage('SETTINGBI', false, true);
        let profileStore = Config.getLocalStorage('PROFILEBI', false, true);
        let menuStore = Config.getLocalStorage('MENUBI');
        let menuTypeStore = Config.getLocalStorage('MENUTYPEBI');
        // let formInfoStore = Config.getLocalStorage('FORMINFOBI');
        let themeStore = Config.getLocalStorage('THEMEBI');

        Config.language = Config.getLocalStorage('langBI') === 'en' ? "01" : "84";
        Config.color = themeStore && Config.isJson(themeStore) ? JSON.parse(themeStore) : null;

        if (typeof menuTypeStore !== "undefined") {
            Config.menuType = Number(menuTypeStore);
        }

        if(tokenStore && Config.isJson(tokenStore)) tokenStore = JSON.parse(tokenStore);
        if (tokenStore && tokenStore.id && profileStore && settingStore) {
            settingStore = JSON.parse(settingStore);
            profileStore = JSON.parse(profileStore);
            // formInfoStore = JSON.parse(formInfoStore);
            menuStore = JSON.parse(menuStore);

            Config.menu = menuStore;
            Config.profile = profileStore;
            Config.setting = settingStore;
            // Config.formInfo = formInfoStore;

            const expire = tokenStore.expire || 0;
            // console.log('===== expire: ', expire);
            // console.log('===== time: ', time);
            if (expire > time) {
                Config.token = tokenStore;

                const settingLists = yield Api.put('/setting/list',{limit:10000});
                if (settingLists && settingLists.data) {
                    // console.log("================================ settingLists:", settingLists);
                    Config.setting = settingLists.data;
                    Config.setLocalStorage('SETTINGBI', JSON.stringify(Config.setting));

                    //get themes...
                    if(!window.location.pathname.includes('/admin')){
                        const themeLists = yield Api.get('/d94f0000/get-color-code');
                        if (themeLists && themeLists.data) {
                            // console.log("================================ themeLists:", themeLists);
                            yield put({
                                type: types.GET_THEME_COMPLETE,
                                themes: themeLists.data
                            });
                            Config.themes = themeLists.data;
                        } else {
                            console.log('Get themes error');
                        }
                        Config.color = Config.color ? Config.color : Config.themes[0];
                    }

                }
                else{
                    console.log('Get setting error');
                    Config.removeLocalStorage('TOKENBI');
                    Config.removeLocalStorage('SETTINGBI');
                }
                //check maintenance..
                const maintenance = yield checkMaintenance();
                // const maintenance = {
                //     "DateFrom": "2021-06-17 08:00:00",
                //     "DateTo": "2021-06-17 17:00:00"
                // };
                yield put({
                    type: types.GET_SETTING_COMPLETE,
                    token: Config.token,
                    setting: Config.setting,
                    profile: Config.profile,
                    language: Config.language,
                    color: Config.color,
                    maintenance
                });
            } else {
                Config.getToken = true;

                let updateToken = yield Api.put('/auth/check', {token: tokenStore.id});
                if (updateToken && updateToken.data) {
                    const expire = time + 7 * 24 * 60 * 60 * 1000;
                    Config.getToken = false;
                    Config.token = {
                        id: updateToken.data.token,
                        expire: expire
                    };
                    Config.setLocalStorage('TOKENBI', JSON.stringify(Config.token));

                    const settingLists = yield Api.put('/setting/list',{limit:10000});
                    if (settingLists && settingLists.data) {
                        // console.log("================================ settingLists:", settingLists);
                        Config.setting = settingLists.data;
                        Config.setLocalStorage('SETTINGBI', JSON.stringify(Config.setting));
                        //check maintenance..
                        const maintenance = yield checkMaintenance();
                        // const maintenance = {
                        //     "DateFrom": "2021-06-17 08:00:00",
                        //     "DateTo": "2021-06-17 17:00:00"
                        // };
                        yield put({type: types.GET_SETTING_COMPLETE,
                            token: Config.token,
                            setting: Config.setting,
                            profile: Config.profile,
                            language: Config.language,
                            color: Config.color,
                            maintenance
                        });

                        //get themes...
                        if(!window.location.pathname.includes('/admin')){
                            const themeLists = yield Api.get('/d94f0000/get-color-code');
                            if (themeLists && themeLists.data) {
                                // console.log("================================ themeLists:", themeLists);
                                yield put({
                                    type: types.GET_THEME_COMPLETE,
                                    themes: themeLists.data
                                });
                                Config.themes = themeLists.data;
                            } else {
                                console.log('Get themes error');
                            }
                            Config.color = Config.color ? Config.color : Config.themes[0];
                        }

                    }
                    else{
                        console.log('Get setting error');
                        Config.removeLocalStorage('TOKENBI');
                        Config.removeLocalStorage('SETTINGBI');
                    }
                } else {
                    console.log('update token error');
                    Config.removeLocalStorage('TOKENBI');
                    Config.removeLocalStorage('SETTINGBI');
                }
            }
        }
        else {
            Config.getToken = true;

            const tokenResult = yield Api.get('/auth/token');
            if (tokenResult && tokenResult.data) {
                const expire = time + 7 * 24 * 60 * 60 * 1000;

                Config.getToken = false;
                Config.token = {
                    id: tokenResult.data.token,
                    expire: expire
                };
                Config.setLocalStorage('TOKENBI', JSON.stringify(Config.token));
                const settingLists = yield Api.put('/setting/list',{limit:10000});
                if (settingLists && settingLists.data) {
                    // console.log("================================ settingLists:", settingLists);
                    Config.setting = settingLists.data;
                    Config.setLocalStorage('SETTINGBI', JSON.stringify(Config.setting));

                    Config.profile = JSON.parse(Config.getLocalStorage('PROFILEBI', false, true));
                    //check maintenance..
                    const maintenance = yield checkMaintenance();
                    // const maintenance = {
                    //     "DateFrom": "2021-06-17 08:00:00",
                    //     "DateTo": "2021-06-17 17:00:00"
                    // };

                    yield put({type: types.GET_SETTING_COMPLETE,
                        token: Config.token,
                        setting: Config.setting,
                        profile: Config.profile,
                        language: Config.language,
                        color: Config.color,
                        maintenance
                    });
                }
                else{
                    alert('Get setting error');
                    Config.removeLocalStorage('TOKENLBI');
                    Config.removeLocalStorage('SETTINGBI');
                }
            } else {
                console.log('get token error');
                Config.removeLocalStorage('TOKENBI');
                Config.removeLocalStorage('SETTINGBI');
            }
        }
    }
    catch (e) {
        console.log('loading is error');
    }

}

export function* watchLoading() {
    while (true){
        const watcher = yield takeLatest(types.GET_SETTING,loading);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* checkMaintenance() {
    try {
        yield delay(300);
        let res = yield Api.get('/deploy/check');
        return res?.data || null;
    } catch (e) {
        console.log('deploy/check is error');
        return null;
    }
}

export function* changeThemes(data) {
    try {
        yield delay(300);
        Config.color = data.params.theme;
        Config.setLocalStorage('THEMEBI', JSON.stringify(data.params.theme));
        yield put({type: types.CHANGE_THEME_COMPLETE, color: Config.color});
    }
    catch (e) {
        console.log('change theme is error');
    }

}

export function* watchChangeThemes() {
    while (true){
        const watcher = yield takeLatest(types.CHANGE_THEME, changeThemes);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateFilters(data) {
    try {
        yield delay(300);
        yield put({type: types.UPDATE_FILTER_COMPLETE, filters: data.params});
    }
    catch (e) {
        console.log('set filter general is error');
    }

}

export function* watchUpdateFilters() {
    while (true){
        const watcher = yield takeLatest(types.UPDATE_FILTER, updateFilters);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export async function* uploadFile(data) {
    try {
        const body = new FormData();
        if(data.mode==='multiple'){
            for(let i=0;i<data.params.length;i++)
                body.append('files', data.params[i]);
        }
        else{
            body.append('files', data.params);
        }
        let result = yield Api.putCDN('/file/upload',body, data.token);
        if (result && result.code && result.message) {
            if (data.cb) data.cb(result, null);
        } else {
            if (data.cb) data.cb(null, result);
        }
    }
    catch (e) {
        console.log('upload is error');
    }

}

export function* watchUploadFile() {
    while (true){
        const watcher = yield takeLatest(types.UPLOAD,uploadFile);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* login(data) {
    try {
        yield delay(300);
        const login = yield Api.put('/user/login-web',data.params);
        if (login && login.code && login.message) {
            if (data.cb) data.cb(login, null);
        } else {
            if (data.cb) data.cb(null, login.data);
        }
    }
    catch (e) {
        console.log('login is error');
    }

}

export function* watchLogin() {
    while (true){
        const watcher = yield takeLatest(types.LOGIN,login);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getTokenCDN(data) {
    try {
        yield delay(300);
        const response = yield Api.getCDN('/auth/token');
        if (response && response.data) {
            data.cb && data.cb(null, response)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get token cdn is error');
    }

}

export function* watchGetTokenLogin() {
    while (true){
        const watcher = yield takeLatest(types.GET_TOKEN_CDN, getTokenCDN);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getThemes(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/d94f0000/get-color-code');
        if (response && response.data) {
            yield put({type: types.GET_THEME_COMPLETE, themes: response.data});
            data.cb && data.cb(null, response)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get theme is error');
    }

}

export function* watchGetThemes() {
    while (true){
        const watcher = yield takeLatest(types.GET_THEME, getThemes);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export async function getDataForm(data) {
    return new Promise((resolve) => {
        Api.put('/breadcrumbs/get-form-name', data)
            .then(result => {
                return resolve(result);
            });
    } );
}




import {combineReducers} from 'redux';
import Main from '../redux/main/main_reducers';
import Home from '../redux/home/home_reducers';
import General from '../redux/general/general_reducers';
import W94F1000 from '../redux/W9X/W94F1000/W94F1000_reducers';


export default combineReducers({
    main: Main,
    home: Home,
    general: General,
    W94F1000: W94F1000,
});

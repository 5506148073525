export const types = {
    GET_PERMISSION: 'GET_PERMISSION',
    GET_CBO_ORGCHART: 'GET_CBO_ORGCHART',
    GET_CBO_ORGCHART_SUCCESS: 'GET_CBO_ORGCHART_SUCCESS',
    //filter
    GET_CBO_DUTY: 'GET_CBO_DUTY',
    GET_CBO_DUTY_SUCCESS: 'GET_CBO_DUTY_SUCCESS',
    GET_CBO_EMPLOYEE: 'GET_CBO_EMPLOYEE',
    GET_CBO_EMPLOYEE_SUCCESS: 'GET_CBO_EMPLOYEE_SUCCESS',
    GET_CBO_PROJECT: 'GET_CBO_PROJECT',
    GET_CBO_PROJECT_SUCCESS: 'GET_CBO_PROJECT_SUCCESS',

    //export excel
    GENERAL_PASS_PARAM_EXPORT_EXCEL: "GENERAL_PASS_PARAM_EXPORT_EXCEL",
    GENERAL_EXPORT_EXCEL_TEMPLATE: 'GENERAL_EXPORT_EXCEL_TEMPLATE',
};

export async function getPermission(formIDs, cb) {
    let listScreenIDs = [];
    if (Array.isArray(formIDs)) {
        listScreenIDs = formIDs;
    } else {
        listScreenIDs.push(formIDs);
    }
    // const response = await Api.put('/checkPermission', listScreenIDs);
    const response = {
        data: {
            isPer: 4
        }
    };
    if (response && response.data) {
        if (cb) cb(null, response.data.isPer);
    } else {
        if (cb) cb(response, null);
    }
}

//get cbo orgcharts..
export function getOrgCharts(params, cb) {
    return{
        type: types.GET_CBO_ORGCHART,
        params,
        cb
    }
}

//get cbo duty..
export function getCboDuty(params, cb) {
    return{
        type: types.GET_CBO_DUTY,
        params,
        cb
    }
}

//get cbo employees..
export function getCboEmployees(params, cb) {
    return{
        type: types.GET_CBO_EMPLOYEE,
        params,
        cb
    }
}

//get cbo projects..
export function getCboProjects(params, cb) {
    return{
        type: types.GET_CBO_PROJECT,
        params,
        cb
    }
}

//export excel template....
export function exportExcelTemplate(params, cb) {
    return{
        type: types.GENERAL_EXPORT_EXCEL_TEMPLATE,
        params,
        cb
    }
}

//pass parameter export excel....
export function passParametersExportExcel(params, cb) {
    return{
        type: types.GENERAL_PASS_PARAM_EXPORT_EXCEL,
        params,
        cb
    }
}





/**
 * @copyright 2019 @ DigiNet
 * @author TrangHoang
 * @create 21/01/2019
 */
export const types = {
    W94F1000_GET_GROUP_LIST: "W94F1000_GET_GROUP_LIST",
    W94F1000_GET_GROUP_LIST_SUCCESS: "W94F1000_GET_GROUP_LIST_SUCCESS",
    W94F1000_GET_LIST: "W94F1000_GET_LIST",
    W94F1000_GET_LIST_SUCCESS: "W94F1000_GET_LIST_SUCCESS",
    W94F1000_GET_DETAIL: "W94F1000_GET_DETAIL",
    W94F1002_GET_FORM_MASTER: "W94F1002_GET_FORM_MASTER",
    GET_STRUCT_REPORT: 'GET_STRUCT_REPORT',
};

export function getGroupList(params, cb) {
    return {
        type: types.W94F1000_GET_GROUP_LIST,
        params,
        cb
    }
}

export function getList(params, cb) {
    return {
        type: types.W94F1000_GET_LIST,
        params,
        cb
    }
}

export function getDetail(params, cb) {
    return {
        type: types.W94F1000_GET_DETAIL,
        params,
        cb
    }
}

export function getFormMaster(params, cb) {
    return {
        type: types.W94F1002_GET_FORM_MASTER,
        params,
        cb
    }
}

export function getStructReport(params, cb) {
    return{
        type: types.GET_STRUCT_REPORT,
        params,
        cb
    }
}

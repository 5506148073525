import {types} from "./main_actions";

const initialState = {
    color: null,
    user: null,
    setting: null,
    loading: null,
    checkLogin:null,
    filters: null,
    themes: [],
    maintenance: null
};

export default function (state = initialState, action = {}) {
    switch (action.type){
        case types.GET_SETTING_COMPLETE:
            return{
                ...state,
                token: action.token,
                profile: action.profile,
                setting: action.setting,
                language:  action.language,
                color:  action.color,
                maintenance: action.maintenance
            };
        case types.CHANGE_THEME_COMPLETE:
            return{
                ...state,
                color:  action.color,
            };
        case types.UPDATE_FILTER_COMPLETE:
            return{
                ...state,
                filters:  action.filters,
            };
        case types.GET_THEME_COMPLETE:
            return{
                ...state,
                themes:  action.themes,
            };
        default:
            return state;
    }
}
